<template>
  <!--abou image-->
  <!-- <div class="img-gradient">
    <img class="first-slide" src="@/assets/book2.jpg" />
  </div>
  <div class="bg-text">
    <h2 class="head"><b>ABOUT US</b></h2>
    <p class="pt-3 aboutPara">
      We are a company with global vision to offer solutions in IT, Business
      Consulting and Outsourcing Services. We demonstrate a high-level of
      commitment in serving our customers, providing innovative ideas and
      technology solutions to meet business needs.
    </p>
  </div> -->
  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book2.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>ABOUT US</b>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <!--abou image-->

  <!--tec partners-->
  <div class="m-2">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12">
        <div class="accounding pt-5">
          <b class="pt-5">ABOUT US</b>
        </div>
        <div class="text-dark">
          <p class="pt-3 pl-2">
            Welcome to Kenwyn Books, where innovation meets precision in the
            world of financial management. Established with a vision to
            revolutionize the way businesses handle their finances, we are a
            dynamic and forward-thinking company dedicated to delivering
            cutting-edge accounting solutions.
            <br /><br />
            At Kenwyn Books, we understand the challenges that businesses face
            in managing their financial data efficiently. With a passion for
            simplifying complex financial processes, we have developed a
            state-of-the-art accounting system that empowers organizations of
            all sizes to streamline their financial operations, enhance
            accuracy, and make informed decisions.
            <br /><br />
            Our team of skilled professionals brings together expertise in
            finance, technology, and user experience design to create a robust
            and user-friendly accounting system. We pride ourselves on staying
            ahead of industry trends and continually evolving our solutions to
            meet the ever-changing needs of our clients.
            <br /><br />
            What sets us apart is our commitment to providing more than just a
            software solution. We strive to build lasting partnerships with our
            clients, offering unparalleled support and guidance throughout their
            financial journey. Whether you're a small startup or a
            well-established enterprise, our accounting system is tailored to
            fit your unique requirements, ensuring scalability and adaptability
            as your business grows.
            <br /><br />
            Transparency, integrity, and innovation are the cornerstones of our
            company culture. We believe in empowering businesses to focus on
            what they do best by simplifying their financial processes. With
            Kenwyn Books, you can trust that your financial data is in capable
            hands, allowing you to drive your business forward with confidence.
            <br /><br />
            Join us on the journey towards financial efficiency and success.
            Explore the possibilities with Kenwyn Books - where your success is
            our business.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-5">
    <h4 class="tecPart"><b>TECHNOLOGY PARTNERS</b></h4>
    <div class="row pt-4 pl-5">
      <div class="col-md-3 col-sm-6 col-lg-3">
        <img
          class="pl-5 pt-4 text-center"
          style="width: 200px"
          src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-1.a7858c2b.png"
        />
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <img
          class="pl-5 pt-4 text-center"
          style="width: 200px"
          src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-2.5388542b.png"
        />
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <img
          class="pl-5 pt-4 text-center"
          style="width: 200px"
          src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-3.10f11173.png"
        />
      </div>
      <div class="col-md-3 col-sm-6 col-lg-3">
        <img
          class="pl-5 pt-4 text-center"
          style="width: 200px"
          src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-4.245a1d82.png"
        />
      </div>
    </div>
  </div>
  <!--tec partners-->
  <br />
  <!--newsletter page-->
  <div class="row pb-5 pr-5 pl-3 rowColor">
    <div class="col-md-4 col-sm-4 col-lg-4 pt-5 rowClr">
      <h5 class="newLetter"><b>GET OUR NEWSLETTER</b></h5>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 rowClr">
      <div class="pt-5">
        <input
          type="text"
          class="form-control"
          placeholder="Your Email Address"
        />
      </div>
    </div>
    <div class="col-md-2 col-sm-2 col-lg-2 pl-3 rowClr">
      <div class="pt-5">
        <button type="button" class="btn btn-outline-light">SUBSCRIBE</button>
      </div>
    </div>
  </div>
  <!--newsletter page-->
</template>
<style scoped>
.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}
.rowColor {
  background-color: green;
}
.newLetter {
  color: white;
}
.tecPart {
  text-align: center;
}
.head {
  font-size: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.accounding {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}

.acc_text {
  text-align: left;
  color: rgb(0, 0, 0);
  font-size: 17px;
}
/* .img-gradient {
  position: relative;
  display: inline-block;
} */

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
/* .img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  ); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
} */
/* .img-gradient img {
  display: block;
} */
.aboutPara {
  color: rgb(255, 255, 255);
  font-size: 11px;
  letter-spacing: 1px;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}
</style>
